<!--行政区-->
<template>
  <bm-overlay ref="customOverlay" pane="labelPane" style="position: relative;" @draw="draw">
    <div class="building" v-if="level == 3" ref="building" @click="handleClick">{{msg.city}} {{msg.price ? (msg.price).toFixed(2) : ''}}{{active == 0?'万元':'元/㎡'}} <span v-if="active == 0">({{msg.counts}}套)</span> </div>
    <div class="sample" @click="handleClick" ref="sample" :style="{'background': level == 1 ? '#00D3C4' : '#7DB92E','zIndex':'10'}" v-else>
      <div class="house-name">{{msg.city}}</div>
       <div class="house-msg" v-if="active==0">{{msg.price ? (msg.price).toFixed(2) : ''}}万</div>
      <div class="house-msg" v-if="active!=0">{{msg.avprice ? (msg.avprice/10000).toFixed(2) : ''}}万/㎡</div>
      <div class="house-num">{{msg.counts}}{{active == 0 ? '套':'新盘'}}</div>
    </div>

  </bm-overlay>
</template>
<script>
import { BmOverlay } from "vue-baidu-map";

export default {
  name: "districtItem",
  data() {
    return {};
  },
  methods: {
    handleClick() {
      this.$emit("item-click", this.position,this.msg.city,this.msg.id);
    },

    draw({ el, BMap, map }) {
      const { lng, lat } = this.position;
      let width = null;
      let height = null;
      if (this.level == 3) {
        width = this.$refs.building.clientWidth;
        height = this.$refs.building.clientHeight;
      } else {
        width = this.$refs.sample.clientWidth;
        height = this.$refs.sample.clientHeight;
      }
    
      const pixel = map.pointToOverlayPixel(new BMap.Point(lng, lat));
      el.style.left = pixel.x - width/2 + "px";
      el.style.top = pixel.y - height/2 + "px";
    },
  },
  watch: {
    position: {
      handler() {
        this.$refs.customOverlay.reload();
      },
      deep: true,
    },
  },

  props: ["msg", "level", "position","active"],
  components: {
    BmOverlay,
  },
};
</script>
<style lang='less' scoped>
.sample {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
  background: #00d3c4;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  position: absolute;

  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.2;
  }
  &.active {
    background: #f79015;
  }
}
.building {
  background-color: #02bb7f;
  color: #fff;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding: 0 14px;
  border-radius: 12px;
  cursor: pointer;
  position: absolute;
  white-space: nowrap;
  span {
    color: #dedede;
  }
}
</style>