<template>
  <div class="map-box">
    <navbar title="地图找房" />
    <van-tabs v-model="active" sticky background="#FAFAFA" title-active-color="#00D3C4" title-inactive-color="#444" color="#00D3C4" line-width="187px" line-height="2px" @click="onClick">
      <van-tab v-for="(item, index) in tabsTitle" :title="item" :key="index">
      </van-tab>
    </van-tabs>

    <baidu-map class="bm-view" ak="3m0AqdZmu1466T4OmwHbwhGSCBFEAGrM" :dragging="dragging" :center="center" :zoom="zoom" :min-zoom="1" :maxn-zoom="19" @zoomend="zoomend" @touchmove="touchmove" @touchend="touchend">
      <bm-navigation></bm-navigation>
      <div v-for="item in list" :key="item.id">
        <my-overlay :msg="item" :position="{lng: item.lng, lat: item.lat}" @item-click="handleClick" :level="level" :active="active" />
      </div>
      <!-- <bm-overlay pane="labelPane" :class="{sample: true, active}" @draw="draw" @mouseover.native="bmactive = true" @mouseleave.native="bmactive = false">
          <div class="house-name">集美区</div>:position="{lng: item.lng, lat: item.lat}"
          <div class="house-msg">5万/㎡</div>
          <div class="house-num">100套</div>
      </bm-overlay> -->
    </baidu-map>
  </div>
</template>

<script>
import navbar from "../../components/NavBar.vue";
import MyOverlay from "../../components/map/districtItem.vue";

import { BaiduMap, BmNavigation, BmOverlay } from "vue-baidu-map";
export default {
  // name: "map",
  data() {
    return {
      active: 0,
      tabsTitle: ["二手房", "新房"],
      center: { lng: 118.097244, lat: 24.520152 },
      zoom: 11,
      zoomLevel_2: 14,
      zoomLevel_3: 16,
      searchLevel: 1, // 当前数据查询等级，1查询行政区，2查询乡镇，3查询楼盘/小区
      firstList: [],
      secondList: [],
      thirdList: [],
      bmactive: false,
      level: 1,
      list: [],
      dragging: false,
      city: "",
      area: "",
      zero: "",
    };
  },
  mounted() {
    this.onClick();
  },
  methods: {
    onClick() {
      if (this.zoom < this.zoomLevel_2) {
        this.getHomeCity(this.active);
      } else if (this.zoom < this.zoomLevel_3) {
        this.getDistrict();
      } else {
        this.getTitle();
      }
    },
    zoomend(e) {
      this.zoom = e.target.getZoom();
      if (this.zoom < this.zoomLevel_2) {
        this.level = 1;
        this.getHomeCity();
      } else if (this.zoom < this.zoomLevel_3) {
        this.level = 2;
        this.getDistrict();
      } else {
        this.level = 3;
        this.getTitle();
      }
      if (this.searchLevel != this.level) {
        this.searchLevel = this.level;
        //重新请求数据
      }
    },
    handleClick(position, name, id) {
      if (this.level == 1) {
        this.city = name;
        this.getDistrict();
        this.zoom = this.zoomLevel_2;
        this.center = position;
      } else if (this.level == 2) {
        this.area = name;
        this.getTitle();
        this.zoom = this.zoomLevel_3;
        this.center = position;
      } else {
        if (this.active == 1) {
          this.$router.push({
            path: "/buildingDetail",
            query: {
              id: id,
            },
          });
        } else if (this.active == 0) {
          this.$router.push({
            path: "/secondHouseList",
            query: {
              commid: id,
            },
          });
        }
      }
    },
    touchmove() {
      this.dragging = true;
    },
    touchend() {
      this.dragging = false;
    },
    //获取地区 一级
    getHomeCity(active) {
      if (active != undefined) {
        this.zero = active;
      }
      const data = {
        active: this.zero,
      };
      this.$post("home/getHomeCity", data).then((res) => {
        if (res.status == 200) {
          this.list = res.data.list;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },

    getDistrict() {
      const data = {
        active: this.active,
      };
      this.$post("home/getHomeDistrict", data).then((res) => {
        this.list = [];
        if (res.status == 200) {
          this.list = res.data.districtList;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },

    getTitle() {
      const data = {
        active: this.active,
      };
      this.$post("home/getHomeTitle", data).then((res) => {
        if (res.status == 200) {
          this.list = res.data.titleList;
        }else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  components: {
    navbar,
    BaiduMap,
    BmNavigation,
    BmOverlay,
    MyOverlay,
  },
};
</script>

<style lang="less" scoped>
.map-box {
  /deep/.van-tabs {
    background-color: #fff;
    .van-tab {
      background-color: #fff;
    }
  }
  .bm-view {
    width: 100%;
    height: calc(100vh - 91px);
  }
}
</style>